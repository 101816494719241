var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.orderInformation)?_c('div',{staticClass:"pageWrapper"},[_c('div',{staticClass:"topPart"},[_c('section',{staticClass:"pageData"},[_c('H2',[_vm._v("Add new order")]),_c('div',{staticClass:"stepBar"},[(_vm.isMobile)?_c('StepBar',{attrs:{"pageAmount":_vm.pageAmount,"currentPage":_vm.currentPage,"titles":[
            'Add products',
            'Choose customer',
            'Delivery information',
            'Confirm' ]}}):_c('PageBar',{attrs:{"setPage":_vm.setPage,"pageAmount":_vm.pageAmount,"currentPage":_vm.currentPage,"titles":[
            'Add products',
            'Choose customer',
            'Delivery information',
            'Confirm' ]}})],1)],1)]),_c('div',{staticClass:"orderModal"},[_c('section',{staticClass:"pageData"},[_c('div',[(_vm.currentPage == 1 && _vm.continueButton != undefined)?_c('TabProducts',{attrs:{"products":_vm.orderInformation.products,"continueButton":_vm.continueButton}}):_vm._e(),(_vm.currentPage == 2)?_c('TabCustomer',{attrs:{"continueButton":_vm.continueButton,"userData":_vm.orderInformation.userData,"setUserData":_vm.setUserData}}):_vm._e(),(_vm.currentPage == 3)?_c('TabDelivery',{attrs:{"updateDateValues":_vm.updateDateValues,"shops":_vm.shops,"orderInformation":_vm.orderInformation}}):_vm._e(),(_vm.currentPage == 4)?_c('TabConfirm',{attrs:{"setPage":_vm.setPage,"userData":_vm.orderInformation.userData,"orderInformation":_vm.orderInformation,"products":_vm.orderInformation.products}}):_vm._e()],1),_c('div',{staticClass:"buttons"},[_c('MainButton',{attrs:{"tabIndex":-1,"clickFunction":_vm.cancel,"styleClass":"default","title":"Cancel"}}),(_vm.currentPage > 1)?_c('MainButton',{ref:"prev",attrs:{"tabIndex":-1,"clickFunction":function () { return _vm.changePage(-1); },"styleClass":"secondary","title":"Previous"}}):_vm._e(),(_vm.currentPage < _vm.pageAmount)?_c('MainButton',{ref:"continue",attrs:{"tabIndex":0,"clickFunction":function () { return _vm.changePage(1); },"styleClass":"primary","title":"Continue"}}):_vm._e(),(_vm.currentPage == _vm.pageAmount)?_c('MainButton',{ref:"addOrder",attrs:{"tabIndex":-1,"clickFunction":function () { return _vm.addOrder(); },"styleClass":"primary","title":"Add"}}):_vm._e()],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }