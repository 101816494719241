<template>
  <div class="pageWrapper" v-if="orderInformation">
    <div class="topPart">
      <section class="pageData">
        <H2>Add new order</H2>
        <!-- Step bar -->
        <div class="stepBar">
          <StepBar
            v-if="isMobile"
            :pageAmount="pageAmount"
            :currentPage="currentPage"
            :titles="[
              'Add products',
              'Choose customer',
              'Delivery information',
              'Confirm',
            ]"
          />
          <PageBar
            :setPage="setPage"
            v-else
            :pageAmount="pageAmount"
            :currentPage="currentPage"
            :titles="[
              'Add products',
              'Choose customer',
              'Delivery information',
              'Confirm',
            ]"
          />
        </div>
      </section>
    </div>
    <div class="orderModal">
      <!-- Current page -->
      <section class="pageData">
        <div>
          <TabProducts
            :products="orderInformation.products"
            :continueButton="continueButton"
            v-if="currentPage == 1 && continueButton != undefined"
          />
          <TabCustomer
            :continueButton="continueButton"
            :userData="orderInformation.userData"
            :setUserData="setUserData"
            v-if="currentPage == 2"
          />
          <TabDelivery
            :updateDateValues="updateDateValues"
            :shops="shops"
            :orderInformation="orderInformation"
            v-if="currentPage == 3"
          />
          <TabConfirm
            :setPage="setPage"
            :userData="orderInformation.userData"
            :orderInformation="orderInformation"
            :products="orderInformation.products"
            v-if="currentPage == 4"
          />
        </div>
        <!-- Change page -->
        <div class="buttons">
          <MainButton
            :tabIndex="-1"
            :clickFunction="cancel"
            styleClass="default"
            title="Cancel"
          />
          <MainButton
            :tabIndex="-1"
            ref="prev"
            v-if="currentPage > 1"
            :clickFunction="() => changePage(-1)"
            styleClass="secondary"
            title="Previous"
          />
          <MainButton
            :tabIndex="0"
            ref="continue"
            v-if="currentPage < pageAmount"
            :clickFunction="() => changePage(1)"
            styleClass="primary"
            title="Continue"
          />
          <MainButton
            ref="addOrder"
            :tabIndex="-1"
            v-if="currentPage == pageAmount"
            :clickFunction="() => addOrder()"
            styleClass="primary"
            title="Add"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

import MainButton from '@/layout/MainButton'
import AddNewCustomer from '@/components/customers/AddNewCustomer'

import TabProducts from '@/components/orders/addOrder/TabProducts'
import TabCustomer from '@/components/orders/addOrder/TabCustomer'
import TabDelivery from '@/components/orders/addOrder/TabDelivery'
import TabConfirm from '@/components/orders/addOrder/TabConfirm'
import StepBar from '@/components/bars/StepBar'
import PageBar from '@/components/bars/PageBar'
import H2 from '@/layout/typography/H2'
import { formatDate } from '../../globalFunctions/date'

export default {
  components: {
    AddNewCustomer,
    MainButton,
    TabProducts,
    TabCustomer,
    TabDelivery,
    TabConfirm,
    StepBar,
    PageBar,
    H2,
  },
  data() {
    return {
      error: '',
      errorPage: 0,
      pageAmount: 4,
      continueButton: undefined,
      orderInformation: {},
      shops: undefined,
      baseOrderInformation: {
        products: [],
        userData: {},
        dateMonth: String(new Date().getMonth() + 1).padStart(2, '0'),
        dateYear: new Date().getFullYear(),
        dateDay: String(new Date().getDate()).padStart(2, '0'),
        pickupDeilveryTimeStart:
          String(('0' + new Date().getHours()).slice(-2)) +
          ':' +
          String(new Date().getMinutes()),
        pickupDeilveryTimeEnd: '',
        pickupDeliveryMethod: 'in_shop',
        store: null,
        paymentMethod: null,
        note: '',
      },
    }
  },
  created() {
    if (
      JSON.stringify(this.orderInformation) !=
        JSON.stringify(this.baseOrderInformation) &&
      this.$store.state.addOrderInformation
    ) {
      this.orderInformation = JSON.parse(
        JSON.stringify(this.$store.state.addOrderInformation)
      )
    } else {
      this.orderInformation = JSON.parse(
        JSON.stringify(this.baseOrderInformation)
      )
    }

    axios.get('storage/getCompanyShops').then((res) => {
      this.shops = res.data
    })
  },
  mounted() {
    if (this.$refs.continue) this.continueButton = this.$refs.continue.$el
  },
  watch: {
    orderInformation: {
      handler: function (data) {
        if (data != this.baseOrderInformation) {
          this.$store.commit('SET_ADD_ORDER_INFORMATION', data)
        }
      },
      deep: true,
      immediate: false,
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
    currentPage: {
      get() {
        return Number(this.$route.params.currentPage)
      },
      set(newVal) {
        if (this.$route.params.currentPage != newVal) {
          this.$router.replace({ params: { currentPage: Number(newVal) } })
        }
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      JSON.stringify(this.orderInformation) ==
      JSON.stringify(this.baseOrderInformation)
    ) {
      this.$store.commit('SET_ADD_ORDER_INFORMATION', null)
    }
    next()
  },
  methods: {
    updateDateValues(date) {
      if (date) {
        let values = String(date).split('-')
        this.orderInformation.dateYear = String(values[0])
        this.orderInformation.dateMonth = String(values[1])
        this.orderInformation.dateDay = String(values[2])
      }
    },
    validate() {
      let foundError = false
      this.orderInformation.products.forEach((element) => {
        if (element.productUnit == 'undefined') {
          this.error = element.productName + ' is missing a unit'
          this.errorPage = 1
          foundError = true
        }
      })
      if (foundError) {
        return false
      }

      if (this.orderInformation.products.length == 0) {
        this.error = 'No product added'
        this.errorPage = 1
        return false
      }

      if (this.orderInformation.userData == undefined) {
        this.error = 'No customer selected'
        this.errorPage = 2
        return false
      }

      if (this.orderInformation.dateMonth == undefined) {
        this.error = 'No month selected!'
        this.errorPage = 3
        return false
      }
      if (this.orderInformation.dateYear == undefined) {
        this.error = 'No year selected!'
        this.errorPage = 3
        return false
      }
      if (this.orderInformation.dateDay == undefined) {
        this.error = 'No day selected!'
        this.errorPage = 3
        return false
      }
      if (this.orderInformation.pickupDeilveryTimeStart == '') {
        this.error = 'No pickuptime selected!'
        this.errorPage = 3
        return false
      }
      if (this.orderInformation.pickupDeliveryMethod == undefined) {
        this.error = 'No delivery method selected!'
        this.errorPage = 3
        return false
      }
      if (this.orderInformation.store == undefined) {
        this.error = 'No store selected!'
        this.errorPage = 3
        return false
      }
      if (this.orderInformation.paymentMethod == undefined) {
        this.error = 'No payment method selected!'
        this.errorPage = 3
        return false
      }

      return true
    },
    displayError() {
      alert(this.error)
    },
    formatDate,
    changePage(val) {
      this.continueButton.getElementsByTagName('button')[0].style.border =
        '2px solid #27795C'
      this.currentPage += Number(val)
    },
    setPage(val) {
      this.currentPage = Number(val)
    },
    setUserData(data) {
      this.orderInformation.userData = data
      this.$forceUpdate()
    },
    cancel() {
      this.$store.commit('SET_ADD_ORDER_INFORMATION', null)
      this.$router.push({ name: 'Orders' })
    },
    addOrder() {
      if (this.validate() == false) {
        this.setPage(this.errorPage)
        return alert(this.error)
      }

      let newOrder = new Object()
      let dateObj = new Date(
        this.orderInformation.dateMonth +
          '/' +
          this.orderInformation.dateDay +
          '/' +
          this.orderInformation.dateYear
      )
      newOrder.userId = this.orderInformation.userData.id
      newOrder.companyId = this.$route.params.companyId
      newOrder.paymentMethod = this.orderInformation.paymentMethod
      newOrder.deliveryMethod = this.orderInformation.pickupDeliveryMethod
      newOrder.datePickup = this.formatDate(dateObj)

      if (this.orderInformation.pickupDeilveryTimeEnd == '') {
        newOrder.timePickupDelivery = String(
          this.orderInformation.pickupDeilveryTimeStart
        )
      } else {
        newOrder.timePickupDelivery =
          String(this.orderInformation.pickupDeilveryTimeStart) +
          ' - ' +
          String(this.orderInformation.pickupDeilveryTimeEnd)
      }
      newOrder.shopId = this.orderInformation.store
      newOrder.otherInfo = this.orderInformation.note
      newOrder.creator = this.$store.state.selectedUser.id

      newOrder.products = this.orderInformation.products

      this.$socket.emit('addOrder', newOrder, (res) => {
        console.log(res)
      })

      this.$store.commit('SET_ADD_ORDER_INFORMATION', null)
      this.$router.push({ name: 'Orders' })
    },
  },
}
</script>

<style scoped>
.topPart {
  position: relative;
  max-width: 850px;
  height: auto;
  display: flex;
  margin: auto;
}

.buttons {
  position: absolute;
  bottom: -45px;
  right: 0;
  float: right;
}

.title {
  margin: 0px 5px 20px 5px;
}

.orderModal {
  padding: 40px;
  position: relative;
  max-width: 850px;
  min-height: 450px;
  border-radius: 10px;
  background: white;
  display: flex;
  margin: auto;
  border: 1px solid rgb(223, 223, 223);
  padding-bottom: 70px;
}

.pageData {
  position: relative;
  width: 100%;
}
.buttons span {
  margin: 5px;
}
.stepBar {
  position: relative;
  width: 100%;
}
@media only screen and (max-width: 1000px) {
  .buttons {
    width: 100%;
    display: flex;
  }
}
</style>
