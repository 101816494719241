<template>
  <div class="popupBox">
    <div class="popContent">
      <div class="popTopRow">
        <ion-icon
          style="cursor: pointer"
          @click="closePopup"
          name="close-outline"
        ></ion-icon>
      </div>
      <div class="popMiddleRow">
        <p class="popTitle">Add new customer</p>
        <div class="inputSection">
          <div><p class="inputSectionTitle">Customer name</p></div>
          <div class="inputBox">
            <div style="margin-right: 10px">
              <span>Firstname <i style="color: red">*</i></span
              ><input v-model="customerInfo.firstname" type="text" />
            </div>
            <div style="margin-left: 10px">
              <span>Lastname</span
              ><input v-model="customerInfo.lastname" type="text" />
            </div>
          </div>
        </div>
        <div class="inputSection">
          <div><p class="inputSectionTitle">Contact information</p></div>
          <div class="inputBox">
            <div style="margin-right: 10px">
              <span>Email address</span
              ><input v-model="customerInfo.email" type="text" />
            </div>
            <div style="margin-left: 10px">
              <span>Phone <i style="color: red"></i></span
              ><input v-model="customerInfo.tel" type="text" />
            </div>
          </div>
        </div>
        <div class="inputSection">
          <div><p class="inputSectionTitle">Address</p></div>
          <div class="inputBox">
            <div style="margin-right: 10px">
              <span>Address</span
              ><input v-model="customerInfo.address" type="text" />
            </div>
            <div style="margin-left: 10px">
              <span>Zip</span><input v-model="customerInfo.zip" type="text" />
            </div>
          </div>
          <div class="inputBox">
            <div style="margin-right: 10px">
              <span>City</span><input v-model="customerInfo.city" type="text" />
            </div>
          </div>
        </div>
        <div class="inputSectionBox">
          <div><p class="inputSectionTitle">Note</p></div>
          <div class="inputBox">
            <div>
              <span
                >For example their preferred way of contact or customer delivery
                requests.</span
              ><textarea v-model="customerInfo.note"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="popBottomRow">
        <MainButton
          :clickFunction="
            () => {
              closePopup()
            }
          "
          title="Cancel"
          styleClass="secondary"
          :buttonStyle="buttonStyle"
        />
        <MainButton
          :clickFunction="
            () => {
              addCustomer()
            }
          "
          title="Continue"
          styleClass="primary"
          :buttonStyle="buttonStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

import ProductImage from '@/components/products/allProducts/ProductImage'
import Loader from '@/components/Loader'
import MainButton from '@/layout/MainButton'

export default {
  components: {
    Loader,
    ProductImage,
    MainButton,
  },
  props: ['addUser'],
  data() {
    return {
      customerInfo: {
        firstname: '',
        lastname: '',
        email: '',
        tel: '',
        address: '',
        zip: '',
        city: '',
        note: '',
      },
      buttonStyle: {
        padding: '10px 25px 8px 25px',
        fontSize: '10pt',
      },
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
  },
  methods: {
    addCustomer() {
      this.customerInfo.name =
        this.customerInfo.firstname + ' ' + this.customerInfo.lastname
      if (this.customerInfo.firstname == '') {
        return alert('Firstname can not be empty!')
      }
      axios
        .post('customers/addNewCustomer', {
          customerInfo: this.customerInfo,
        })
        .then((res) => {
          this.customerInfo.id = res.data.insertId
          this.addUser(this.customerInfo)
          this.closePopup()
        })
    },
    closePopup() {
      this.$store.commit('CLEAR_POPUP')
    },
  },
}
</script>

<style scoped src="@/assets/css/popup.css"></style>
<style scoped lang="scss">

.popContent {
  width: 100%;
  position: relative;
  display: inline;
}

/* Pop top row */

.popTopRow {
  width: 100%;
  text-align: right;
  font-size: 24pt;
}

/* Pop middle row */
.popMiddleRow {
  position: relative;
  margin: 10px;
  margin-top: 0px;
  margin-bottom: 30px;
}
.popTitle {
  font-size: 15pt;
  font-weight: bold;
  color: #444444;
  font-family: 'Roboto', sans-serif;
}

.inputSection {
  margin-top: 10px;
  position: relative;
}
.inputSectionTitle {
  font-weight: bold;
  color: #444444;
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;
}
.inputSectionSmallTitle {
  font-weight: normal;
  color: #6e6e6e;
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;
  font-size: 9pt;
}

.inputSectionBox {
  position: relative;
}
.inputSectionBox span {
  font-size: 8pt;
  font-weight: lighter;
}
.inputSectionBox textarea {
  max-height: 100px;
  border: 1px solid #e3e3e3;
  background: #f3f3f3;
  position: relative;
  padding: 5px;
  color: rgb(95, 95, 95);
  display: block;
  box-sizing: content-box;
  border-radius: 5px;
}
.inputSection div {
  position: relative;
  justify-content: space-between;
  display: flex;
}

.inputBox div {
  position: relative;
  display: flex;
  flex-direction: column;
}
.inputSection span {
  font-family: 'Roboto', sans-serif;
  font-size: 8pt;
}
.inputSection input {
  border: 1px solid #999999;
  border-radius: 5px;
  padding: 10px;
}

/* Pop bottom row */

.popBottomRow {
  position: relative;
  margin: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1100px) {
  .inputBox {
    display: block !important;
    width: 100%;
  }

  .inputBox input {
    width: 100%;
  }
  .inputBox div {
    margin: 0 !important;
  }
}
</style>