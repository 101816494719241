<template>
  <div class="infoWrapperBox">
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style>
.infoWrapperBox {
  background: white;
  padding: 20px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 3px 0px rgb(175, 175, 175);
  -moz-box-shadow: 0px 0px 3px 0px rgb(175, 175, 175);
  box-shadow: 0px 0px 3px 0px rgb(223, 223, 223);
}
</style>