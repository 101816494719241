<template>
  <InfoWrapperBox>
    <H4>Images</H4>
    <div class="images">
      <div class="mutipleImages">
        <div class="image-div" :key="image.name" v-for="image in images">
          <div v-if="images.length > 0" class="imageTopbar">
            <ion-icon
              name="trash-outline"
              style="
                color: white;
                font-size: 12pt;
                margin: auto;
                float: right;
                cursor: pointer;
              "
              @click="deleteImage(image)"
            />
          </div>
          <img
            @click="changeSelectedImage(image)"
            :src="image.src"
            :alt="image.name"
          />
        </div>
        <div class="image-div">
          <label for="uploadInput">
            <img v-if="images.length < 6" src="@/assets/img/add.png" alt="" />
          </label>
        </div>
      </div>
      <input
        id="uploadInput"
        type="file"
        @change="afterComplete"
        accept="image/*"
        class="input-file"
      />
    </div>
  </InfoWrapperBox>
</template>

<script>
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/storage'
import H4 from '../../../layout/typography/H4.vue'
import InfoWrapperBox from '../../../layout/InfoWrapperBox.vue'
let uuid = require('uuid')

export default {
  components: {
    H4,
    InfoWrapperBox
  },
  props: ['product'],
  data() {
    return {
      images: [],
      selectedImage: {
        src: 'https://via.placeholder.com/430x230.png?text=No+image+added',
      },
    }
  },
  async created() {
    this.getImages()
  },
  methods: {
    changeSelectedImage(image) {
      this.selectedImage = image
    },
    deleteImage(image) {
      var storageRef = firebase.storage().ref()

      var desertRef = storageRef.child('images/' + image.imageName + '.jpg')

      desertRef
        .delete()
        .then(function () {
          console.log('Deleted image')
        })
        .catch(function (error) {
          console.log(error)
        })

      axios
        .post('products/deleteImage', {
          imageName: image.imageName,
        })
        .then((res) => {
          let index = this.images.findIndex(
            (el) => el.imageName == image.imageName
          )
          this.images.splice(index, 1)
          this.changeSelectedImage(this.images[0])
        })
    },
    getImages() {
      axios
        .post('products/getImages', {
          productId: this.product.id,
        })
        .then(async (res) => {
          if (res.data.length) {
            res.data.forEach(async (image) => {
              try {
                var storageRef = firebase.storage().ref()
                var imageRef = storageRef.child(`images/${image.imageName}.jpg`)

                var downloadURL = await imageRef.getDownloadURL()
                this.images.push({
                  src: downloadURL,
                  imageName: image.imageName,
                })
                this.selectedImage = this.images[0]
              } catch (error) {
                console.log(error)
              }
            })
          }
        })
    },
    async afterComplete(e) {
      var imageName = uuid.v1()
      try {
        let file = e.target.files[0]
        var metadata = {
          contentType: 'image/jpg',
        }
        var storageRef = firebase.storage().ref()
        var imageRef = storageRef.child(`images/${imageName}.jpg`)
        await imageRef.put(file, metadata)
        var downloadURL = await imageRef.getDownloadURL()
        this.images.push({ src: downloadURL, imageName })
        this.selectedImage = this.images[0]

        axios
          .post('products/addImage', {
            imageName,
            productId: this.product.id,
          })
          .then((res) => {
            console.log(res)
          })
      } catch (error) {
        if (error.code == 'storage/unauthorized') {
          alert('The image is too large (< 500kb).')
        }
        console.log(error)
      }
    },
  },
}
</script>

<style>
.box {
  width: 100%;
  position: relative;
  background: white;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.boxHeader {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 20px;
}

.boxHeader p,
.boxHeader h2 {
  font-size: 10pt;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.boxHeader p {
  font-size: 15pt;
}
.boxHeader h2 {
  color: #4e73bc;
  text-decoration: underline;
}
.images {
  display: flex;
  width: 100%;
  position: relative;
}

.image-div {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  width: 200px;
  margin: 5px;
}
.image-div img {
  width: 100%;
  object-fit: cover;
  height: 130px !important;
}
.imageTopbar {
  position: absolute;
  width: 100%;
  background: rgba(31, 31, 31, 0.5);
  padding: 5px;
  display: none;
  left: 0;
}

.image-div:hover > .imageTopbar {
  display: inline;
}

.imageTopbar:hover {
  display: inline;
}

.image {
  width: 100%;
}

.imageSelector {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mutipleImages {
  position: relative;
  width: 100%;
  display: flex;
}
.imageSelector img {
  width: calc(50% - 5px);
  height: calc((300px / 3) - 3px) !important;
  object-fit: cover;
  margin: 5px 5px 0 0;
  border-radius: 3px;
  cursor: pointer;
}

.imageSelector img:hover {
  filter: grayscale(20%);
}

.addImageButton {
  justify-content: center;
  display: inline-block;
  margin: 5px 5px 0 0;
  width: 50%;
  height: calc((300px / 3) - 3px) !important;
  display: flex;
  vertical-align: center;
  background: rgb(38, 105, 136);
  border-radius: 3px;
  cursor: pointer;
}

.addImageButton:hover {
  background: rgb(49, 128, 165);
}

.addImageButton svg {
  margin: auto;
  color: white;
}

#uploadInput {
  display: none;
}
</style>