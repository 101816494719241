<template>
  <div class="confirm">
    <p class="mediumTitle">Confirm</p>
    <br />
    <!-- Products -->
    <p class="smallTitle">
      Products<ion-icon @click="setPage(2)" name="create-outline"></ion-icon
      ><span @click="setPage(1)">edit</span>
    </p>
    <br />

    <div class="blockBox">
      <p v-if="!products.length > 0">No products added</p>
      <Product
        v-for="(product, i) in products"
        :key="Number(String(product.id) + String(i))"
        :product="product"
        :index="i"
        :removeProduct="removeProduct"
      />
    </div>
    <br /><br />

    <!-- Customer -->
    <p class="smallTitle">
      Customer<ion-icon @click="setPage(1)" name="create-outline"></ion-icon
      ><span @click="setPage(2)">edit</span>
    </p>
    <br />
    <CustomerBlock :userData="userData" v-if="userData" />
    <div class="blockBox" v-else>
      <p>No customer added</p>
    </div>
    <br /><br />

    <!-- Delivery info -->
    <p class="smallTitle">
      Delivery information<ion-icon
        @click="setPage(3)"
        name="create-outline"
      ></ion-icon
      ><span @click="setPage(3)">edit</span>
    </p>
    <br />
    <div class="deliveryInformation blockBox">
      <div>
        <section>
          <p>Pickup/delivery time</p>
          <span>{{
            orderInformation.pickupDeilveryTimeStart
              ? `${orderInformation.pickupDeilveryTimeStart} ${orderInformation.pickupDeilveryTimeEnd}`
              : 'No info given'
          }}</span>
        </section>
        <section>
          <p>Note</p>
          <span>{{
            orderInformation.note ? orderInformation.note : 'No info given'
          }}</span>
        </section>
      </div>
      <div>
        <section>
          <p>Pickup/Delivery method</p>
          <span>{{
            orderInformation.pickupDeliveryMethod
              ? orderInformation.pickupDeliveryMethod
              : 'No info given'
          }}</span>
        </section>
        <section>
          <p>Store</p>
          <span>{{
            orderInformation.store ? orderInformation.store : 'No info given'
          }}</span>
        </section>
      </div>
      <div>
        <section>
          <p>Date of delivery</p>
          <span>{{
            orderInformation.dateDay
              ? dateOfDelivery()
              : 'No info given'
          }}</span>
        </section>
        <section>
          <p>Payment</p>
          <span>{{
            orderInformation.paymentMethod
              ? orderInformation.paymentMethod
              : 'No info given'
          }}</span>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Product from '@/components/orders/addOrder/Product'
import CustomerBlock from '@/components/customers/CustomerBlock'
import { formatDate } from '../../../globalFunctions/date'

export default {
  components: {
    Product,
    CustomerBlock,
  },
  props: ['products', 'orderInformation', 'setPage', 'userData'],
  created() {
    this.$nextTick((_) => {
      // this.searchElement = this.$refs.addOrder
      // console.log(this.searchElement);
      // // this.searchElement.getElementsByTagName('input')[0].focus()
    })
  },
  methods: {
    dateOfDelivery() {
      return formatDate(new Date(`${this.orderInformation.dateYear}/${this.orderInformation.dateMonth}/${this.orderInformation.dateDay}`))
    },
    removeProduct(index) {
      this.products.splice(index, 1)
    },
  },
}
</script>

<style scoped lang="scss">
.customerInfo {
  display: flex;
  position: relative;
  width: 100%;
}

.blockBox {
  font-family: 'Roboto', sans-serif;
  -webkit-box-shadow: 0px 0px 9px -4px rgba(163, 163, 163, 1);
  -moz-box-shadow: 0px 0px 9px -4px rgba(163, 163, 163, 1);
  box-shadow: 0px 0px 9px -4px rgba(163, 163, 163, 1);
  padding: 15px 25px;
  border-radius: 10px;
}

.blockBox div section p {
  color: $accent;
  font-weight: bold;
  font-size: 9pt;
}

.blockBox div section span {
  position: relative;
  color: #444444;
  width: calc(100% - 120px);
}

.deliveryInformation {
  display: flex;
}

.confirm hr {
  opacity: 0.5;
  margin: 5px 0;
}
.deliveryInformation p {
  margin: 5px 0;
  width: 200px;
}

.deliveryInformation div {
  position: relative;
}
.smallTitle {
  font-size: 11pt;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #444444;
}
.smallTitle ion-icon {
  float: right;
  position: relative;
  top: 2px;
  margin-left: 3px;
  cursor: pointer;
}

.smallTitle span {
  cursor: pointer;
  float: right;
  font-weight: lighter;
}
.mediumTitle {
  font-size: 15pt;
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
  color: #444444;
}

@media only screen and (max-width: 1000px) {
  .confirm {
    font-size: 8pt;
    padding-bottom: 25px;
  }
  .customerBlock div {
    justify-content: space-between;
  }
  .customerInfo {
    display: block;
  }
  .deliveryInformation {
    display: block;
  }

  .deliveryInformation div {
    display: flex;
    justify-content: space-between;
  }
  .deliveryInformation div p {
    width: auto;
    font-size: 8pt;
  }
  .smallTitle {
    margin-bottom: 10px 0;
  }

  .blockBox div section p {
    font-size: 8pt;
  }
}
</style>