<template>
  <div>
    <!-- Header -->
    <p class="mediumTitle">Search for an existing customer</p>
    <Search
      :continueButton="continueButton"
      ref="search"
      :dataFunction="getCustomersByName"
      :returnFunction="addUser"
      :addFromSearch="{ display: true, func: addNewUser }"
      :displayKey="'name'"
      :placeholder="'Search by name, phone or email'"
      :icon="'fa-user'"
    />

    <!-- Display user data -->
    <div v-if="userData.name == null">
      <div class="textBox">
        <p class="mediumTitle">Is the customer new?</p>
        <p class="smallTitle">Add the customer if they aren't in the system.</p>
      </div>
      <div @click="addNewUser" class="addNewCustomer">
        <ion-icon name="add-outline"></ion-icon>
        <span>add new customer</span>
      </div>
    </div>
    <CustomerBlock :userData="userData" v-if="userData.name != null" />
  </div>
</template>

<script>
import axios from 'axios'
import Search from '@/components/Search'
import AddUserPopupBox from '@/components/customers/AddUserPopupBox'
import CustomerBlock from '@/components/customers/CustomerBlock'

export default {
  props: ['continueButton', 'setUserData', 'userData'],
  components: {
    Search,
    AddUserPopupBox,
    CustomerBlock,
  },
  data() {
    return {
      prevButton: undefined,
      searchElement: undefined,
    }
  },
  created() {
    this.$nextTick((_) => {
      this.searchElement = this.$refs.search.$el
      this.searchElement.getElementsByTagName('input')[0].focus()
    })
  },
  methods: {
    addNewUser() {
      this.$store.commit('SET_POPUP', {
        component: AddUserPopupBox,
        properties: { addUser: this.addUser },
      })
    },
    addUser(customerInformation) {
      this.setUserData(customerInformation)
    },
    getCustomersByName(customerNameQuery) {
      return new Promise((resolve) => {
        axios
          .post('customers/getCustomersFromSearch', {
            companyId: this.$route.params.companyId,
            customerQuery: customerNameQuery,
          })
          .then((res) => {
            resolve(res.data)
          })
      })
    },
  },
}
</script>

<style scoped lang="scss">
/*  */

.textBox {
  margin: 20px 0;
}
.smallTitle {
  font-size: 11pt;
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
  color: #444444;
}
.mediumTitle {
  font-size: 15pt;
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
  color: #444444;
}
.addNewCustomer {
  cursor: pointer;
  display: flex;
  position: relative;
}
.addNewCustomer span {
  text-decoration: underline;
}
.addNewCustomer ion-icon {
  font-size: 15pt;
}

@media only screen and (max-width: 1000px) {
  .customerSections {
    width: 100%;
    position: relative;
    display: contents;
  }
  .customerSections div {
    width: 100%;
  }
  .customerSections div section {
    word-break: normal;
  }
}
</style>